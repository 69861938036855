import baseAPi from "./base-api-service"
import shaerdConfig from "../shared-config";
import sharedConfig from "../shared-config";

export default {
    //
    //
    // Login
    loginEmail: async function (email, password) {
        return await baseAPi.postData(shaerdConfig.api.authEndpoint, {email, password});
    },
    loginGoogle: async function (gToken) {
        return await baseAPi.postData(shaerdConfig.api.authEndpoint, {gToken});
    },
    loginApple: async function (aToken) {
        return await baseAPi.postData(shaerdConfig.api.authEndpoint, {aToken, isWeb: true});
    },
    //
    //
    // User actions
    getUser: async function () {
        if (localStorage.getItem(shaerdConfig.storage.token) !== null) {
            return await baseAPi.getData(
                shaerdConfig.api.authEndpoint + shaerdConfig.api.path.profile
            );
        }
        return null;
    },
    updateProfile: async function (data) {
        if (localStorage.getItem(shaerdConfig.storage.token) !== null) {
            return await baseAPi.putFormData(sharedConfig.api.authEndpoint, data);
        }
        return null;
    },
    registerEmailPassword: async function(
        username,
        email,
        password
    ) {
        return await baseAPi.postData(
            shaerdConfig.api.authEndpoint + shaerdConfig.api.path.register,
            { username, email, password }
        );
    },
    // Org login
    orgLoginEmail: async function(email, password) {
        return await baseAPi.postData(shaerdConfig.api.organization.authEndpoint, { email, password });
    },

    // Org get profile
    orgGetProfile: async function(email, password) {
        return await baseAPi.postData(shaerdConfig.api.organization.profileEndpoint, { email, password });
    },
}