<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div class="container">
            <div class="header">
              <h1>
                Contact support
              </h1>
              <p>
                We are here to help!
              </p>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center" v-if="!haveReadDoc">
                <strong><p>Before contacting the support</p></strong>
                <p>We try to keep an active documentation document where you can get answers to some of the most answered questions. Make sure to check that document first, to see if it helps <a href="/support/documentation">go to documentation</a></p>
                <p>If not feel free to contact us</p>
                <md-button class="md-primary feedback-button" @click="haveReadyDocumentation">Yes, I still need help</md-button>
              </div>
              <div class="md-layout-item md-size-100 text-center" v-if="haveReadDoc">
                <md-field :class="{ 'md-invalid': isValid }">
                  <md-icon class="icon-textarea">feedback</md-icon>
                  <label class="label-textarea">{{!hasEdited ? 'Write a descriptive message of how we can help you' : 'Support message'}}</label>
                  <md-textarea v-model="feedback" type="text"></md-textarea>
                  <span class="md-error">{{ isValid}}</span>
                </md-field>
                <md-button class="md-primary feedback-button" :disabled="hasEdited && !!isValid" @click="sendSupportForm">Send</md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedConfig from "../../../shared/shared-config";
import BreadCrumbs from "../../../shared/components/Breadcrumb";
import Vue from "vue";

export default {
  bodyClass: "Send-Feedback",
  components: {
    BreadCrumbs
  },
  created() {
    document.title = "Send Feedback - Organization";
  },
  data() {
    return {
      hasEdited: false,
      haveReadDoc: false,
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    };
  },
  mounted() {
  },
  methods: {
    sendSupportForm() {
      this.$loading(true);
      this.$store.dispatch(sharedConfig.store.other.SEND_FEEDBACK).then(() => {
        this.$loading(false);
        const instance = Vue.$toast.open({
          message: "Thank you! We will get back to you as soon as possible",
          type: "success",
          position: "top-right"
        });
      }).catch((e) => {
        this.$loading(true);
        const instance = Vue.$toast.open({
          message: "Could not send message",
          type: "error",
          position: "top-right"
        });
      });
    },
    haveReadyDocumentation() {
      this.haveReadDoc = true;
    }
  },
  computed: {
    feedback: {
      get() {
        return this.$store.getters.supportMessage;
      },
      set(newValue) {
        this.hasEdited = newValue && newValue.length > 0;
        this.$store.dispatch(sharedConfig.store.other.SET_FEEDBACK, newValue);
      }
    },
    isValid() {
      if (!this.hasEdited) {
        return null;
      } else {
        return this.$store.getters.supportValid;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-textarea {
  padding-left: 22px !important;
  min-height: 300px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}
.label-textarea {
  margin-left: 33px;
}
.feedback-button {
  margin-top: 44px;
}
</style>
