import {Category} from "./Category.model";
import {Language} from "./Language.model";
import {Status} from "./Status.model";

export class StaticData {
    constructor(json) {
        this.categories = json['categories'].map((category) => new Category(category)).sort((a, b) => (a.order < b.order ? -1 : 1));
        this.languages = json['languages'].map((language) => new Language(language))
            .sort((a,b) => a.name.localeCompare(b.name))
            .sort((a, b) => (a.order > b.order ? -1 : 1))
            .sort((a, b) => a.prio ? -1 : 1);
        this.status = json["status"].map((status) => new Status(status));
    }
}
