import baseApi from '../../../shared/services/base-api-service'
import {DashboardStats} from "../models/Dashboard.model";
import sharedConfig from "../../../shared/shared-config";

const state = {
    hasLoaded: false,
    isLoading: false,
    stats: null,
}
const getters = {
    dashboardStats(state) {
        return state.stats;
    },
    hasLoadedStats(state) {
        return state.hasLoaded;
    },
    isLoadingStats(state) {
        return state.isLoading;
    }
}

const actions = {
    // Used for logging out etc.
    [sharedConfig.store.analytics.CLEAR_DATA]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.store.analytics.CLEAR_DATA_SUCCESS);
    },
    [sharedConfig.store.analytics.GET_STATS]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.store.analytics.GET_STATS);
        return new Promise((resolve, reject) =>  {
            baseApi.getData(sharedConfig.api.organization.statsEndpoint)
                .then((statsData) => {
                    commit(sharedConfig.store.analytics.GET_STATS_SUCCESS, statsData);
                    console.log('Has loaded stats');
                    resolve();
                }).catch((e) => {
                commit(sharedConfig.store.analytics.GET_STATS_ERROR, e);
                console.log(e);
                reject(e);
            });
        })

    },
}

const mutations = {
    [sharedConfig.store.analytics.CLEAR_DATA_SUCCESS]: (state) => {
        state.stats = null;
        state.hasLoaded = false;
        state.isLoading = false;
    },
    [sharedConfig.store.analytics.GET_STATS]: (state) => {
        state.isLoading = true;
    },
    [sharedConfig.store.analytics.GET_STATS_SUCCESS]: (state, resp) => {
        state.stats = new DashboardStats(resp.data);
        state.isLoading = false;
        state.hasLoaded = true;
    },
    [sharedConfig.store.analytics.GET_STATS_ERROR]: (state, e) => {
        state.isLoading = false;
        this.$toasted.error('There was an error processing your request.', {
            duration: 8000,
            position: 'top-center'
        });
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};