import storage from "../../../shared/utils/localstorage-handler";
import sharedConfig from "../../../shared/shared-config";
import storyspotService from "../../../shared/services/storyspot-service"
import {Storyspot} from "../../../shared/models/Storyspot.model";
import {StorySpotPlace} from "../../../shared/models/StoryspotPlace.model";

const state = {
    hasLoadedStoryspots: false,
    hasLoadedStoryspotsPlaces: false,
    isLoadingStoryspots: false,
    storyspots: [],
    storyspotPlaces: [],
}

const getters = {
    getCurrentUserStoryspots(state) {
        return state.storyspots;
    },
    getStoryspotLoadStatus(state) {
        return {
            isLoading: state.isLoadingStoryspots,
            hasLoaded: state.hasLoadedStoryspots,
        };
    },
    getCurrentUserStoryspotPlaces(state) {
        return state.storyspotPlaces;
    },
    hasLoadingStoryspotPlaces(state) {
        return state.hasLoadedStoryspotsPlaces;
    }
}

const actions = {
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
        return new Promise((resolve, reject) => {
            if (storage.get(sharedConfig.storage.token)) {
                storyspotService.getOrganzationStoryspots().then((storyspots) => {
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_SUCCESS, storyspots);
                    dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_REQUEST);
                    resolve();
                }).catch((e) => {
                    console.log(e);
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR, e);
                    reject();
                });
            }
        });
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_REQUEST]: ({commit, dispatch}) => {
        console.log('did trigger places request');
        commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_REQUEST);
        return new Promise(async (resolve, reject) => {
            if (state.hasLoadedStoryspots) {
                let spList = [];
                for (let i=0; i<state.storyspots.length; i++) {
                    let storyspot = state.storyspots[i];
                    let sp = await dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST, storyspot.id);
                    spList.push(new StorySpotPlace(sp['storyspot'], sp['place']));
                }
                commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_SUCCESS, spList)
            } else {
                console.log('Has not loaded storyspots');
            }
        });
    },
    [sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST]: (
        { commit, dispatch },
        id
    ) => {
        return new Promise((resolve, reject) => {
            storyspotService
                .getStoryspotPlaceById(id)
                .then(sp => {
                    resolve(sp);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST]: (state) => {
        state.isLoadingStoryspots = true;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_REQUEST]: (state) => {
        state.hasLoadedStoryspotsPlaces = false;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_SUCCESS]: (state, spList) => {
        state.storyspotPlaces = spList;
        console.log('did load', state.storyspotPlaces)
        state.hasLoadedStoryspotsPlaces = true;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_SUCCESS]: (state, storyspotsData) => {
        state.storyspots = storyspotsData.data
            .map((jsonData) => new Storyspot(jsonData))
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
            .sort((a,b) => (a.status.name < b.status.name ? -1 : 1));
        state.hasLoadedStoryspots = true;
        state.isLoadingStoryspots = false;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingStoryspots = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};