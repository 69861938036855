<template>
  <div>
    <div class="wrapper">
      <div class="main-storyspot">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20"
          >
            <img v-if="user.picture"
                :src="user.picture"
                alt="Profile Image"
                class="rounded img-fluid displayImage"
            />
            <div v-if="user.picture == null" class="no-profile-pic-container">
              <md-icon class="no-profile-pic">face</md-icon>
            </div>
          </div>
          <div
              class="md-layout-item md-size-70"
          >
            <div class="text-container">
              <div class="blockquote large-paragraph">
                <small>
                  {{ user.email }} <strong> • </strong> {{ user.username }}
                </small>
              </div>
              <div class="text-left">
              <h4 class="title">
                <router-link :to="'/our-storyspots/'">
                  <span class="tim-note">{{ user.fullName }}</span>
                </router-link>
              </h4>
              <p class="text-description large-paragraph">
                {{user.description}}
              </p>
              <div class="blockquote undefined sub-menu">
              </div>
              </div>
            </div>
          </div>
          <div>
            <li class="md-list-item">
              <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <drop-down direction="down">
                  <md-icon>more_vert</md-icon>
                  <ul class="dropdown-menu dropdown-menu-right" style="z-index: 9999">
                    <li class="dropdown-header">Actions</li>
                    <li>
                      <a class="dropdown-item" @click="copyLink">
                        <md-icon>email</md-icon>
                        <p>Email</p>
                      </a>
                    </li>
                    <li class="dropdown-header">Settings</li>
                  </ul>
                </drop-down>
              </a>
            </li>
          </div>
        </div>
      </div>
    </div>
    <li class="dropdown-divider"></li>
  </div>
</template>
<script>
import Badge from "../Badge";
import Vue from "vue";
import QrCode from "../QrCode";
import sharedConfig from "../../shared-config"
import {copyString} from "../../utils/general-utils";

export default {
  components: {
    QrCode,
    Badge
  },
  props: {
    user: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  methods: {
    playAudio() {
      console.log(this.storyspot.audio);
      this.$store.dispatch(sharedConfig.store.audioPlayer.SET_AUDIO, this.storyspot.audio)
    },
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  }
};
</script>
<style lang="scss" scoped>


.wrapper {
  margin-top: 28px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  object-fit: contain !important;
  // Just to fill width
  width: 100px;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  width: 100px;
}

.no-profile-pic {
  font-size: 100px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}

</style>