import {Status} from "./Status.model";

export class Category {
    constructor(json) {
        this.id = json.id != null ? json.id : "";
        this.name = json.name != null ? json.name : "";
        this.color = json.color != null ? json.color : "#269da8";
        this.order = json.order != null ? json.order : 100;
        this.status = json.status != null ? new Status(json.status) : null;
    }
}
