<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <div class="header">
              <md-icon class="line">dashboard</md-icon>
              <h1 class="line">Dashboard</h1>
              <h3 class="line">{{ getOrgName }}</h3>
            </div>
            <div class="header">
              <md-icon class="line">bar_chart</md-icon>
              <h4 class="line">Statistics for last {{ diffDays }} days</h4>
            </div>
            <div v-if="isLoading || !stats" class="loader md-layout md-gutter md-alignment-center-center">
                <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="!isLoading && stats">
              <LineChart class="stats-over-time" :chartData="getUniqCountries.graph"></LineChart>

              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
                  <TotalNumbers class="stats-over-time" :uniqAuthorsVisits="this.stats.uniqAuthorVisitsCount"
                                :uniqListens="this.stats.uniqStoryspotPlayingCount"
                                :uniqStoryspotsVisits="this.stats.uniqVisitsStoryspotsCount"></TotalNumbers>
                </div>
                <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
                  <Summary class="stats-over-time" :visitorsGrowth="this.stats.getVisitsGrowth()"
                           :listensGrowth="this.stats.getListensGrowth()"></Summary>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
                  <BarChart class="stats-over-time" title="Uniq visits by countries"
                            :chartData="getUniqCountries.countries.graph"></BarChart>
                </div>
                <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
                  <BarChart class="stats-over-time" title="Uniq visits by cities"
                            :chartData="getUniqCountries.cities.graph"></BarChart>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
                  <PieChart class="stats-over-time" :chartData="getDeviceLang.data"></PieChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import sharedConfig from "../../../shared/shared-config";
import LineChart from "../components/analytics/LineChart";
import PieChart from "../components/analytics/PieChart";
import Summary from "../components/analytics/SummaryByMonth";
import BarChart from "../components/analytics/BarChart";
import TotalNumbers from "../components/analytics/TotalNumbers";


export default {
  bodyClass: "dashboard",
  components: {
    LineChart,
    PieChart,
    Summary,
    BarChart,
    TotalNumbers
  },
  created() {
    document.title = this.getOrg && this.getOrg.name ? this.getOrg.name + " - Organization" : 'Storyspot - Organization';
  },
  data() {
    return {
      isLoading: null,
    }
  },
  methods: {},
  mounted() {
    if (!this.$store.getters.hasLoadedStats) {
      this.isLoading = true;
      this.$store.dispatch(sharedConfig.store.analytics.GET_STATS).then(() => {
        this.isLoading = false;
      });

    }
  },
  computed: {
    diffDays() {
      return this.$store.getters.diff;
    },
    stats() {
      return this.$store.getters.dashboardStats;
    },
    getUniqCountries() {
      return this.stats.getUniqCountries(this.settings.fromDate, this.settings.toDate);
    },
    getDeviceLang() {
      return this.stats.getDeviceLang(this.settings.fromDate, this.settings.toDate);
    },
    settings() {
      return this.$store.getters.getDashboardDays;
    },
    getOrg() {
      if (this.$store.getters.getOrganization)
        return this.$store.getters.getOrganization;
      else
        return null;
    },
    getOrgName() {
      return this.getOrg && this.getOrg.profile.fullName ? this.getOrg.profile.fullName : ''
    },

  }
}
</script>

<style scoped>

.section {
  min-height: 80vh !important;
  padding-top: 20px !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 25px auto 0;
  width: fit-content;
}

.header .line:first-child {
  margin: 0 3px 0 0;
}

.header .line:not(:first-child) {
  margin: 0 10px 0 0;
}

a {
  cursor: pointer;
}

.stats-over-time {
  margin-top: 50px;
}

.md-datepicker {
  max-width: 145px;
}



</style>