<template>
  <md-card class="card-sum">
    <h3 class="text-center">Total numbers</h3>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <div class="item">
          <h5 class="start">Uniq listens:</h5>
          <h5><strong>{{ uniqListens }}</strong></h5>
        </div>
        <div class="item">
          <h5>Uniq Storyspot visits:</h5>
          <h5><strong>{{ uniqStoryspotsVisits }}</strong></h5>
        </div>
        <div class="item">
          <h5 class="start">Uniq author visits:</h5>
          <h5><strong>{{ uniqAuthorsVisits }}</strong></h5>
        </div>
      </div>
    </div>
  </md-card>
</template>
<script>
export default {
  name: 'Stat-total-summary',
  components: {},
  data() {
    return {};
  },
  props: {
    uniqStoryspotsVisits: {
      type: Number,
      default: 0,
    },
    uniqAuthorsVisits: {
      type: Number,
      default: 0,
    },
    uniqListens: {
      type: Number,
      default: 0,
    }
  }
}
</script>
<style scoped>
.down {
  color: red !important;
}

.up {
  color: green !important;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item strong {
  padding-left: 8px;
}

.card-sum {
  padding: 0 50px 50px 50px;
}

p.start {
  text-align: justify;
  display: inline;
}

h1.start {
  margin: 0;
  display: inline-block;
}
</style>