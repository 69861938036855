<template>
  <div>
    <div id="material-kit">
      <div :class="{ 'nav-open': NavbarStore.showNavbar }">
        <router-view name="header"/>
        <div style="height: 100px"/>
        <router-view/>
        <router-view name="footer"/>
      </div>
      <AudioPlayer class="audio-player on-top-player"/>
    </div>
    <modal v-if="showModal" @close="closeModal">
      <template slot="header">
        <h1 class="modal-title">Disclaimer</h1>
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <p>This Organization portal in in <strong>early Beta.</strong> and is subject to continiues changes</p>
        <br>
        <p>All functionality may not be in place, and issues and bugs may be present. We are happy for you to test this
          portal and be sure to give us
          <router-link to="/feedback">feedback</router-link>
          whenever you notice any issue or have a feature requests.
        </p>
        <br>
        <br>
        <p>We appreciate your understanding and your help!</p>
      </template>

    </modal>
    <DatePicker/>
  </div>
</template>

<script>
import Modal from "../../shared/components/Modal";
import sharedConfig from "../../shared/shared-config"
import AudioPlayer from "../../shared/components/AudioPlayer";
import sessionHandler from "../../shared/utils/session-handler";
import DatePicker from "./components/DatePicker/DatePicker";

export default {
  name: 'App',
  components: {
    DatePicker,
    AudioPlayer,
    Modal
  },
  data() {
    return {
      showModal: false
    }
  },
  mounted() {
    if (!window.location.href.indexOf('/login') > -1) {
      if (this.$store.getters.isAuthenticated && this.$store.getters.getOrganization != null) {
        if (!sessionHandler.get(sharedConfig.session.disclaimer)) {
          this.$store.dispatch(sharedConfig.store.actions.AUTH.GET_PROFILE);
        }
        if (!this.$store.getters.hasLoadedStoryspots) {
          Promise.all([this.$store.dispatch(sharedConfig.store.analytics.GET_STATS), this.$store.dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST), this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST)]).then(() => {
          }).catch((e) => {
          })
        }
      }
      if (!sessionHandler.get(sharedConfig.session.disclaimer)) {
        sessionHandler.set(sharedConfig.session.disclaimer, new Date());
      }
    }
  },
  methods: {
    displayModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  }
}
</script>
<style>

.main-content {
  margin-top: 60px;
}

.on-top-player {
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>
