<template>
  <div class="main-navigation">
    <md-toolbar
        id="toolbar"
        md-elevation="0"
        class="md-primary md-absolute"
        :class="extraNavClasses"
    >
      <div class="md-toolbar-row md-collapse-lateral">
        <div class="md-toolbar-section-start">
          <router-link to="/">
            <div class="avatar">
              <img
                  src="../assets/logo_white_icon.png"
                  alt="Logo"
                  width="60"
                  height="60"
                  class="img-fluid"
              />
            </div>
          </router-link>
        </div>
        <div class="md-toolbar-section-end">
          <md-button
              class="md-just-icon md-simple md-toolbar-toggle"
              :class="{ toggled: toggledClass }"
              @click="toggleNavbarMobile()"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div class="md-collapse">
            <div class="md-collapse-wrapper">
              <mobile-menu nav-mobile-section-start="false">
                <!-- Here you can add your items from the section-start of your toolbar -->
              </mobile-menu>
              <md-list>
                <DatePickerIcon/>
                <md-list-item to="/dashboard">
                  <md-icon>dashboard</md-icon>
                  <p class="menu-text">Dashboard</p>
                </md-list-item>

                <md-list-item to="/our-storyspots">
                  <md-icon>explore</md-icon>
                  <p class="menu-text">Our Storyspots</p>
                </md-list-item>

                <li class="md-list-item">
                  <a
                      href="javascript:void(0)"
                      class="md-list-item-router md-list-item-container md-button-clean dropdown"
                  >
                    <div class="md-list-item-content">
                      <drop-down direction="down">
                        <md-button
                            slot="title"
                            class="md-button md-button-link md-white md-simple dropdown-toggle"
                            data-toggle="dropdown"
                        >
                          <i class="material-icons">menu</i>
                        </md-button>
                        <ul class="dropdown-menu dropdown-with-icons">
                          <li>
                            <router-link to="/profile" class="dropdown-item"><md-icon class="material-icons">account_circle</md-icon>Profile</router-link>
                          </li>
                          <li>
                            <router-link to="/our-users" class="dropdown-item"><md-icon class="material-icons">group</md-icon>Users</router-link>
                          </li>
                          <li class="dropdown-divider"></li>
                          <li>
                            <router-link to="/support/documentation" class="dropdown-item"><md-icon class="material-icons">help_center</md-icon>Documentation</router-link>
                          </li>
                          <li>
                            <router-link to="/support" class="dropdown-item"><md-icon class="material-icons">contact_support</md-icon>Contact support</router-link>
                          </li>
                          <li class="dropdown-divider"></li>
                          <li>
                            <a href="https://app.storyspot.se"><md-icon class="material-icons">house</md-icon>User portal</a>
                          </li>
                          <li v-if="isAdmin">
                            <a href="https://admin.storyspot.se" class="dropdown-item"><md-icon class="material-icons">admin_panel_settings</md-icon>Admin portal</a>
                          </li>
                          <li class="dropdown-divider"></li>
                          <li>
                            <router-link  to="/feedback" class="dropdown-item"><md-icon class="material-icons">volunteer_activism</md-icon>We love feedback</router-link>
                          </li>
                          <li class="dropdown-divider"></li>
                          <li>
                            <a class="dropdown-item"  @click="logout"><md-icon class="material-icons">logout</md-icon>Sign out</a>
                          </li>
                        </ul>
                      </drop-down>
                    </div>
                  </a>
                </li>
              </md-list>
            </div>
          </div>
        </div>
      </div>
    </md-toolbar>
  </div>
</template>

<script>
import sharedConfig from "../../../shared/shared-config";

let resizeTimeout;

function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "../../../shared/components/MobileMenu";
import DatePickerIcon from "./DatePicker/DatePickerIcon";

export default {
  components: {
    DatePickerIcon,
    MobileMenu
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    hasOrganization() {
      return this.$store.getters.isPartOfOrganization;
    }
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
          document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({block: "end", behavior: "smooth"});
      }
    },
    logout() {
      this.$store.dispatch(sharedConfig.store.actions.AUTH.LOGOUT_REQUEST);
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};
</script>
<style scoped>
.main-navigation {
  z-index: 9999;
}
.menu-text {
  color: white !important;
  font-weight: bold !important;
}
.section {
padding-top: 20px !important;
}
</style>