<template>
  <div>
    <div class="avatar">
      <div class="image-container">
        <input
            type="file"
            id="imageFile"
            ref="images"
            @change="selectFile"
            accept="image/*"
            hidden
        />
        <div class="button-container">
          <md-button @click="triggerSelect" class="md-primary md-just-icon md-round">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button v-if="image != null" @click="onDelete" class="md-danger md-just-icon md-round">
            <md-icon>delete</md-icon>
          </md-button>
        </div>
        <img v-if="image"
             :src="getImage"
             alt="Circle Image"
             class="img-raised rounded-circle img-fluid profile-image"
        />
        <div v-if="image == null" class="no-profile-pic-container">
          <md-icon class="no-profile-pic">face</md-icon>
        </div>
      </div>
    </div>
    <modal v-if="showCropper" @close="closeCropper">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeCropper"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <cropper
            v-if="getCropperImage"
            class="cropper"
            ref="cropper"
            :src="getCropperImage"
            :stencil-props="{
		      aspectRatio: 1
	        }"
        />
        <md-button class="md-primary" @click="saveCropper">Save</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {cropImage, resizeImage} from "../utils/image-utils";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Modal from "./Modal";

export default {
  components: {
    Cropper,
    Modal,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showCropper: false,
      croppingImage: null,
    }
  },
  methods: {
    closeCropper() {
      this.showCropper = false;
    },
    async saveCropper() {
      this.showCropper = false;
      const {
        coordinates, ig, visibleArea, canvas
      } = this.$refs.cropper.getResult();
      const config = {
        file: this.croppingImage,
        maxSize: 1200,
        width: coordinates.width,
        height: coordinates.height,
        x: coordinates.left,
        y: coordinates.top,
      };
      let img = await cropImage(config);
      this.$emit("on-selected", img);

    },
    onEdit(image) {

    },
    onDelete() {
      this.$emit("on-delete");
    },
    triggerSelect() {
      document.getElementById("imageFile").click();
    },
    async selectFile() {
      const files = Array.from(this.$refs.images["files"]);
      if (this.value?.length + files.length > 1) {
      } else {
        const config = {
          file: files[0],
          maxSize: 1200
        };
        const img = await resizeImage(config);
        this.croppingImage = img;
        this.showCropper = true;
      }
    },
  },
  computed: {
    getImage() {
      if (this.image && typeof this.image == 'string') {
        return this.image;
      } else {
        return URL.createObjectURL(this.image);
      }
    },
    getCropperImage() {
      if (this.croppingImage && typeof this.croppingImage == 'string') {
        return this.croppingImage;
      } else {
        return URL.createObjectURL(this.croppingImage);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.profile-image {
  aspect-ratio:1;
}
.avatar {
  display: flex;
  justify-content: center;
  min-width: 250px !important;
  min-height: 250px !important;
}

.image-container {
  width: 300px !important;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 240px;
  }
}

.button-container {
  position: absolute;
  right: 0 !important;
}

.md-button.md-fab i, .md-button.md-fab, .md-button.md-just-icon i, .md-button.md-just-icon {
  font-size: 20px !important;
}

.md-button.md-fab, .md-button.md-just-icon {
  min-width: unset !important;
  height: 30px !important;
  width: 30px !important;
}

.md-button.md-round, .md-button.md-round.md-fab, .md-button.md-round.md-just-icon {
  border-radius: 15px !important;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  width: 240px;
  border-radius: 120px;
  border: 2px solid grey;
}

.no-profile-pic {
  font-size: 100px !important;
}

.cropper {
  height: 600px;
  background: #DDD;
}
.vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
  background-color: transparent !important;
}
</style>