export class Language {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.code = json.code;
        this.nativeName = json.nativeName;
        this.prio = json.prio;
        this.order = json.order;
    }
}
