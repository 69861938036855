export class StatObject {

    constructor(object) {
        this.date = object['event_timestamp'] ? new Date(object['event_timestamp']/1000) : null;
        this.uniqUserId = object['user_pseudo_id'];
        this.country = object['country'];
        this.region = object['region'];
        this.city = object['city'];
        this.continent = object['continent'];
        this.platform = object['platform'];
        this.userLanguage = object['userLanguage'];
    }
}