<template>
  <div class="search-container">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-medium-size-50">
          <h1>{{title}} <strong>{{ storyspotCount }}</strong></h1>
        </div>
        <div class="search-bar md-layout-item md-small-size-100 md-medium-size-50  md-large-size-40">
          <md-field class="md-form-group">
            <md-icon>{{ searchIcon }}</md-icon>
            <md-input
                v-model="searchText"
                placeholder="Search..."
                v-on:keyup="didChange"
            ></md-input>
          </md-field>
        </div>
      </div>
     <hr class="rounded"/>
    </div>
</template>
<script>

export default {
  props: {
    searchIcon: {
      type: String,
      default: 'search',
    },
    storyspotCount: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'My storyspots'
    }
  },
  data() {
    return {
      searchText: null,
    }
  },
  methods: {
    didChange() {
      this.$emit("send-searchparameter", this.searchText);
    }
  }
};
</script>
<style>
hr {
  margin: 5px;
  opacity: 0.5;
}

.search-container {
  margin-bottom: 22px;
}

.search-bar {
  margin:auto;
}

/* Rounded border */
hr.rounded {
  margin-top: 22px !important;
  .temp {
    color: #bbb;
    border-top: 2px solid #bbb;
    border-radius: 5px;
    margin-bottom: 22px !important;
  }
}
</style>