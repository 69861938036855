import {StatObject} from "../../../shared/models/Stat.model";
import {
    comparePreviousVScurrent, getCitiesAndCountries,
    getEventsOverTimeByDay, getEventsOverTimeByWeek,
    getMonthTimeRange,
    getToday, graphColors, lineChartv2,
} from "../../../shared/utils/stat-util";
const locale = require('locale-codes')

export class DashboardStats {

    constructor(json) {
        this.uniqAuthorVisits = json.uniqAuthorVisits.map((a) => {
            let item = new StatObject(a);
            item.authorId = a.authorId;
            return item;
        });
        this.uniqStoryspotPlaying = json.uniqVisitsStoryspots.map((a) => {
            let item = new StatObject(a);
            item.storyspotId = a.storyspotId;
            return item;
        });
        this.uniqVisitsStoryspots = json.uniqVisitsStoryspots.map((a) => {
            let item = new StatObject(a);
            item.storyspotId = a.storyspotId;
            return item;
        });

        this.uniqAuthorVisitsCount = Object.keys(this.uniqAuthorVisits).length;
        this.uniqStoryspotPlayingCount = Object.keys(this.uniqStoryspotPlaying).length;
        this.uniqVisitsStoryspotsCount = Object.keys(this.uniqVisitsStoryspots).length;
    }

    getDeviceLang(fromDate, toDate) {
        let total = this.uniqAuthorVisits.concat(this.uniqStoryspotPlaying).concat(this.uniqVisitsStoryspots).filter((a) => a.date > fromDate && a.date < toDate);
        let res = {};
        for (let i = 0; i < total.length; i++) {
            res[total[i].uniqUserId] = total[i]['userLanguage'];
        }
        let values = {};
        for (let i = 0; i < Object.values(res).length; i++) {
            let code = Object.values(res)[i];
                try {
                    let loc = locale.getByTag(code);
                    if (loc) {
                        code = loc.name;
                    }
                }  catch (e) {
                    console.log('no transcode')
                }

            if (!(code in values)) {
                values[code] = 1;
            } else {
                values[code] = values[code] + 1;
            }
        }

        let response = {
            value: values,
            data: {
                labels: Object.keys(values),
                datasets: [
                    {
                        label: '',
                        backgroundColor: graphColors,
                        data: Object.values(values)
                    }],

            },
        };
        return response;
    }


    getAuthorVisitsGrowth() {
        let currentDate = new Date();
        let previousMonth = new Date();
        previousMonth.setMonth(currentDate.getMonth() - 1);

        let currentMonthTimeRange = getMonthTimeRange(currentDate);
        let previousMonthTimeRange = getMonthTimeRange(previousMonth);

        let previous = this.uniqAuthorVisits.filter((a) => a.date > previousMonthTimeRange.start && a.date < previousMonthTimeRange.end);
        let current = this.uniqAuthorVisits.filter((a) => a.date > currentMonthTimeRange.start && a.date < currentMonthTimeRange.end);
        let today = this.uniqAuthorVisits.filter((a) => a.date > getToday().start && a.date < getToday().end);

        let {diff, increase} = comparePreviousVScurrent(current, previous);


        return {
            previous: previous,
            current: current,
            today: today,
            diff: diff,
            increase: increase,
        }
    }

    getListensGrowth() {
        let currentDate = new Date();
        let previousMonth = new Date();
        previousMonth.setMonth(currentDate.getMonth() - 1);

        let currentMonthTimeRange = getMonthTimeRange(currentDate);
        let previousMonthTimeRange = getMonthTimeRange(previousMonth);

        let previous = this.uniqStoryspotPlaying.filter((a) => a.date > previousMonthTimeRange.start && a.date < previousMonthTimeRange.end);
        let current = this.uniqStoryspotPlaying.filter((a) => a.date > currentMonthTimeRange.start && a.date < currentMonthTimeRange.end);
        let today = this.uniqStoryspotPlaying.filter((a) => a.date > getToday().start && a.date < getToday().end);

        let {diff, increase} = comparePreviousVScurrent(current, previous);


        return {
            previous: previous,
            current: current,
            today: today,
            diff: diff,
            increase: increase,
        }
    }

    getVisitsGrowth() {
        let currentDate = new Date();
        let previousMonth = new Date();
        previousMonth.setMonth(currentDate.getMonth() - 1);

        let currentMonthTimeRange = getMonthTimeRange(currentDate);
        let previousMonthTimeRange = getMonthTimeRange(previousMonth);

        let previous = this.uniqVisitsStoryspots.filter((a) => a.date > previousMonthTimeRange.start && a.date < previousMonthTimeRange.end);
        let current = this.uniqVisitsStoryspots.filter((a) => a.date > currentMonthTimeRange.start && a.date < currentMonthTimeRange.end);
        let today = this.uniqVisitsStoryspots.filter((a) => a.date > getToday().start && a.date < getToday().end);

        let {diff, increase} = comparePreviousVScurrent(current, previous);

        return {
            previous: previous,
            current: current,
            today: today,
            diff: diff,
            increase: increase,
        }
    }

    // Horrible functions, quickly to get a prototype up.
    getAuthorVisits(fromDate, toDate) {
        return this.uniqAuthorVisits.filter((a) => a.date > fromDate && a.date < toDate);
    }

    getStoryspotVisits(fromDate, toDate) {
        return this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate);
    }

    getStoryspotListens(fromDate, toDate) {
        return this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate);
    }

    getUniqCountries(fromDate, toDate) {
        let countries = {};
        let cities = {};

        getCitiesAndCountries(this.uniqAuthorVisits.filter((a) => a.date > fromDate && a.date < toDate), cities, countries);
        getCitiesAndCountries(this.uniqStoryspotPlaying.filter((a) => a.date > fromDate && a.date < toDate), cities, countries);
        getCitiesAndCountries(this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate), cities, countries);

        cities = Object.entries(cities)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({...r, [k]: v}), {});
        countries = Object.entries(countries)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({...r, [k]: v}), {});

        let graphValuesUSP = getEventsOverTimeByDay(this.uniqStoryspotPlaying, fromDate, toDate);
        let graphValuesUAV = getEventsOverTimeByDay(this.uniqAuthorVisits, fromDate, toDate);
        let graphValuesUVS = getEventsOverTimeByDay(this.uniqVisitsStoryspots, fromDate, toDate);


        let dataSets = [
            {
                label: 'Author page',
                data: graphValuesUAV.values,
                borderColor: graphColors[0],
                backgroundColor: graphColors[0],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 12,
                pointHitRadius: 12,
                tension: 0.4,
            },
            {
                label: 'Listens',
                data: graphValuesUSP.values,
                borderColor: graphColors[1],
                backgroundColor: graphColors[1],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 12,
                pointHitRadius: 12,
                tension: 0.4
            },
            {
                label: 'Storyspot page',
                data: graphValuesUVS.values,
                borderColor: graphColors[2],
                backgroundColor: graphColors[2],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 12,
                pointHitRadius: 12,
                tension: 0.4
            }
        ];
        let labels = graphValuesUSP.keys.map((a) => {
            const dateSet = a.toDateString().split(' ');
            return `${a.toLocaleString('en-us', {month: 'long'})} ${dateSet[2]}`;
        });
        let graph = lineChartv2(labels, dataSets);

        const reducer = (accumulator, curr) => accumulator + curr;
        return {
            totalCountries: Object.keys(countries).length,//Object.values(countries).reduce(reducerCount),
            totalCities: Object.keys(cities).length,//Object.values(cities).reduce(reducerCount),
            cities: {
                value: cities,
                graph: {
                    labels: Object.keys(cities),
                    datasets: [
                        {
                            borderWidth: 2.5,
                            borderRadius: 8,
                            backgroundColor: graphColors.map((a) => a = a + 'CC'),
                            borderColor: graphColors,
                            data: Object.values(cities),
                        }
                    ]
                }
            },
            countries: {
                value: countries,
                graph: {
                    labels: Object.keys(countries),
                    datasets: [
                        {
                            borderWidth: 2.5,
                            borderRadius: 8,
                            backgroundColor: graphColors.map((a) => a = a + 'CC'),
                            borderColor: graphColors,
                            label: 'Uniq visits per country',
                            data: Object.values(countries),
                        }
                    ]
                }
            },
            graph: graph,
        }
    }
}



